/* @keyframes barprogress {
    0% {
        background-position: 0% 0;
    }
    to {
        background-position: 125% 0;
    }
}

#nprogress .bar {
    height: 6px !important;
    background: #27c4f5
        linear-gradient(
            90deg,
            rgba(9, 144, 0, 1) 0%,
            rgba(4, 228, 77, 1) 50%,
            rgba(4, 227, 238, 1) 100%
        ) !important;
    background-size: 500%;
    animation: 2s linear infinite barprogress, 0.3s fadein;
    width: 100%;
}

#nprogress .peg {
    all: initial !important;
} */

.fi.fis {
    border-radius: 5px;
    width: 24px;
    height: 24px;
}

.google_ad_horizontal {
    width: 320px;
    height: 100px;
}
@media (min-width: 500px) {
    .google_ad_horizontal {
        width: 468px;
        height: 60px;
    }
}
@media (min-width: 800px) {
    .google_ad_horizontal {
        width: 728px;
        height: 90px;
    }
}

.google_ad_rectangle {
    width: 300px;
    height: 200px;
}
@media (min-width: 500px) {
    .google_ad_rectangle {
        width: 300px;
        height: 250px;
    }
}
@media (min-width: 800px) {
    .google_ad_rectangle {
        width: 300px;
        height: 250px;
    }
}

.google_ad_vertical {
    width: 100px;
    height: 320px;
}
@media (min-width: 500px) {
    .google_ad_vertical {
        width: 60px;
        height: 468px;
    }
}
@media (min-width: 800px) {
    .google_ad_vertical {
        width: 90px;
        height: 728px;
    }
}

/* div.bracket {
    justify-content: center;
} */
